import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { Row,Col, Form, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import { Input,Label } from 'reactstrap';
import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import toast, { Toaster } from 'react-hot-toast';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import '../../App.css';
import Select from 'react-select'


const usecase_options = [
  "What is the use case ?",
"Staging Server",
"Micro-Services",
"Development Server",
"Code Repositories",
"CI/CD Server",
"Web Applications",
"Mobile Backend Applications",
"API Backend Applications",
"Microsoft Exchange and SharePoint",
"SAP Business Suite",
"MySQL",
"Microsoft SQL Server",
"PostgreSQL databases",
"Gaming Servers",
"Caching Fleets",
"Batch Processing",
"Distributed Analytics",
"Highly scalable multiplayer gaming",
"SAP Databases",
"SQL Databases",
"NoSQL databases",
"Real time big data analytics",
"Machine learning",
"Computational fluid dynamic",
"Computational finance",
"Seismic analysis",
"Speech recognition",
"Autonomous vehicles",
"Drug discovery"
];



const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Ress = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {




  const queryParams = new URLSearchParams(window.location.search)
  const way = queryParams.get("way")
  const cpu = queryParams.get("cpucount")
  const avgtime = queryParams.get("avgtime")
  const clicks = queryParams.get("clicks")
  const simusers = queryParams.get("simusers")
  const noofclicks2 = queryParams.get("noofclicks2")
  const avgtime2 = queryParams.get("avgtime2")
  const [name, setName] = useState("");
  const [email, setEmail] =  useState("");
  const usecase = queryParams.get("usecase")
  const [phone, setPhone] =  useState("");
  const [response, setResponse] =  useState("");

  const dropdownIndicatorStyles = (base, state) => {
    let changes = {
      // all your override styles
      backgroundColor: '#000000'
    };
    return Object.assign(base, changes);
  };
  

  const inputHandleEvent = (e) =>{
    switch(e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
    }
  };


  const onSendMailButtonClick = async ()=>{
    if(name==undefined || name==""){
        toast('Let us know your name',{
          duration: 4000})
        return;
      }
    if(email==undefined || email==""){
        toast('Share your email to send you the report',{
          duration: 4000})
        return;
      }
      if(phone==undefined || phone==""){
        toast('Share your phone number',{
          duration: 4000})
        return;
      }
    if(!email.includes('@')){
        toast('Email is not valid, provide a proper email',{
          duration: 4000})
        return;
      }
    console.log(name+" "+email+" "+usecase)
    const toastLoadingId = toast.loading("Generating your results")
    const axios = require('axios').default;
    // Make a request for a user with a given ID
    //axios.post('https://6xk6bmp446.execute-api.ap-south-1.amazonaws.com/Dev',{
    axios.post('https://msr-backed-api.azurewebsites.net/api/HttpTrigger2?code=FrGBOiu8HRbjVfOxMO4x7XJDZh9BJmF2gJqLg8BpieGiAzFuGCqpGA==',{
          way:way,
          vcpucount:cpu,
          avgtime:avgtime,
          noofclicks:clicks,
          simultaneoususers:simusers,
          noofclicks2:noofclicks2,
          avgtime2:avgtime2,
          name:name,
          email:email,
          usecase:usecase,
          phone:phone
      })
      .then(function (response) {
        //console.log(response.data);
        console.log("Request is made successfully!.")
        if (response.data.statusCode === 200) {
            toast.dismiss(toastLoadingId);
            toast.success('Minimum server requirement report is sent to your email address '+email, {
              duration: 3000})
            var resp = (response.data.body)
            setResponse(resp);
            setInterval(() => {
              window.location.href = 'https://tekkonnectpro.com/'
            }, 4000);
        }else{
          toast.dismiss(toastLoadingId);
          toast.error("Please try again after sometime!.")
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.dismiss(toastLoadingId);
        toast.error("Please try again after sometime!.")
      })
      .then(function () {
        // always executed
      });
  }

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  function scrollToToolPosition(){
    window.document.getElementById("toolsId").scrollIntoView();
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            To whom and where<br></br>Do we send your results
            </h2>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              </p>

              <div className="reveal-from-bottom" data-reveal-delay="600">
              <div className="p-3 left">
        <Form>
            <FormGroup className="mt-3">
                <Input className="form-input inputPlaceHolderColor" name="name" type="text" onChange={inputHandleEvent} placeholder="Your Name"/>
            </FormGroup>
            <FormGroup className="mt-3">
                <Input className="form-input inputPlaceHolderColor" name="email" type="email" onChange={inputHandleEvent} placeholder="Your Email"/>
            </FormGroup>
            <FormGroup className="mt-3">
                <Input className="form-input inputPlaceHolderColor" name="phone" type="text" onChange={inputHandleEvent} placeholder="Enter your phone number with country code"/>
            </FormGroup>
            <br></br>
            <Button type="button" onClick={onSendMailButtonClick} className="button" size="sm">Send Me My Report</Button>
        </Form>
        <br/>
    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Ress.propTypes = propTypes;
Ress.defaultProps = defaultProps;

export default Ress;