import React, {useRef, useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Button,Row, Col, Form, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { Input,Label } from 'reactstrap';
import Dropdown from 'react-dropdown';
import '../../App.css';

const propTypes = {
  ...SectionSplitProps.types
}



const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Deploy your app on the right cloud server',
    paragraph: 'Get the Optimum Server Configuration for your application'
  };

  const usecase_options = [
    "What is the use case ?",
  "Staging Server",
  "Micro-Services",
  "Development Server",
  "Code Repositories",
  "CI/CD Server",
  "Web Applications",
  "Mobile Backend Applications",
  "API Backend Applications",
  "Microsoft Exchange and SharePoint",
  "SAP Business Suite",
  "MySQL",
  "Microsoft SQL Server",
  "PostgreSQL databases",
  "Gaming Servers",
  "Caching Fleets",
  "Batch Processing",
  "Distributed Analytics",
  "Highly scalable multiplayer gaming",
  "SAP Databases",
  "SQL Databases",
  "NoSQL databases",
  "Real time big data analytics",
  "Machine learning",
  "Computational fluid dynamic",
  "Computational finance",
  "Seismic analysis",
  "Speech recognition",
  "Autonomous vehicles",
  "Drug discovery"
  ];

  const [clickscount, setClicksCount] = useState(undefined);
  const [avgtime, setAvgTimeCount] =  useState(undefined);
  const [cpucount, setCpuCount] =  useState(undefined);
  const [simuserscount,setSimUsersCount] = useState(undefined);
  const [noofClicks2,setNoofClicks2] = useState(undefined);
  const [avgtime2,setavgtime2] = useState(undefined);
  const [button1,setButton1] = useState(0);
  const [button2,setButton2] = useState(0);
  const [usecase1,setUsecase1] = useState(usecase_options[0]);
  const [usecase2,setUsecase2] = useState(usecase_options[0]);

  const updateUsecase1 = (e) => {
    setUsecase1(e.value);
  }
  const updateUsecase2 = (e) => {
    setUsecase2(e.value);
  }


  const handleUserInputChange = (e) =>{
    switch(e.target.name) {
      case "nofcore":
        setCpuCount(e.target.value);
        break;
      case "avgtime":
        setAvgTimeCount(e.target.value);
        break;
      case "nofclicks":
        setClicksCount(e.target.value);
        break;
      case "maxsusers":
        setSimUsersCount(e.target.value);
        break;
      case "noofclicks2":
        setNoofClicks2(e.target.value);
        break;
      case "avgtime2":
        setavgtime2(e.target.value);
        break;
      case "useCase1":
        setUsecase1(e.target.value);
        break;
      case "useCase2":
        setUsecase2(e.target.value);
        break;
          
    }
  };

  const handleButton1 = () =>{
    if(clickscount!=undefined){
      if(avgtime!=undefined){
        if(cpucount!=undefined){
          if (usecase1!=="What is the use case ?"){
            window.location.href = "/fetchresult?way=1&cpucount="+cpucount+"&avgtime="+avgtime+"&clicks="+clickscount+"&usecase="+usecase1;
          }else{
            toast('Specify usecase')
          }
        }else{
          toast('Specify vCPUs')
        }
      }else{
        toast('Specify average request-response time')
    }
  }else{
      toast('Specify no of user click actions per minute')
    }
  }

  const handleButton2 = () =>{
      if(simuserscount!=undefined){
        if(noofClicks2!=undefined){
          if(avgtime2!=undefined){
            if(usecase2!=="What is the use case ?"){
              window.location.href = "/fetchresult?way=2&simusers="+simuserscount+"&noofclicks2="+noofClicks2+"&avgtime2="+avgtime2+"&usecase="+usecase2;
            } else {
              toast('Specify usecase')
            }
          }else{
            toast('Specify average request response time')
          }
        }else{
          toast('Specify no of clicks per user per minute')
        }
      }else{
        toast('Specify assumed simultaneous users per minute')
      }
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
        <div id="toolsId" className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                Calculate CPU and RAM
                  </h3>
                <div className="m-0">
                <div className="row">
                <Form>
                  <div className="col-md-12">
                    <FormGroup>
                      <Input className="form-input inputPlaceHolderColor" name="maxsusers" placeholder="No Concurrent users per minute" type="number" min="10" onChange={handleUserInputChange} required/>
                    </FormGroup>
                    </div>
                    <div className="col-md-12">
                    <FormGroup>
                      <Input className="form-input inputPlaceHolderColor" name="noofclicks2" value={noofClicks2} placeholder="No of clicks per user per minute" type="number" min="10" onChange={handleUserInputChange} required/>
                    </FormGroup>
                    </div>
                  <div className="col-md-12">
                    <FormGroup className="mt-3">
                        <Dropdown options={usecase_options} className="form-control dropdownControl" onChange={updateUsecase2} value={usecase2} placeholder="Select an option" />
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                      <FormGroup>
                        <Input className="form-input inputPlaceHolderColor" name="avgtime2" value={avgtime2} placeholder="Load time taken by the website to load" type="number" min="1" onChange={handleUserInputChange} required/>
                      </FormGroup>
                  </div>
                  <br></br>
                  <div className="col-md-12">
                  <FormGroup className="pt-4">
                  <Button className="button button-wide-mobile button-sm"size="md" onClick={handleButton2} block>Get My Result</Button>
                  </FormGroup>
                  </div>
                  <br></br>
                  <p style={{lineHeight:2, textAlign:"left", color:"#ffffff", fontSize:10}}>
Hint to access load time:<br></br>
&nbsp;&nbsp;1.	In a new tab, open the site for which you want to know the load time.<br></br>
&nbsp;&nbsp;2.	The next step varies depending on the browser you use; here are some examples from commonly used browsers.<br></br>
&nbsp;&nbsp;&nbsp;&nbsp; 2.1.	In case if you are using Microsoft Edge browser, press the F12 key then, select "Open DevTools" to move on to point 3.<br></br>
&nbsp;&nbsp;&nbsp;&nbsp; 2.2.	In case if you are using Safari browser, first enable Develop menu by selecting, Safari > Preferences, click Advanced, then select “Show Develop menu in menu bar”. Now go back to the website and right click on the mouse and select Inspect Element, to move on to point 3.<br></br>
&nbsp;&nbsp;&nbsp;&nbsp; 2.3.	In case if you are using Chrome browser, move on to point 3. <br></br>
&nbsp;&nbsp;3.	To see the load time, select the network tab and reload the page.<br></br>
</p>
                  </Form>
                </div>
                </div>
              </div>
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                Calculate capacity of Server
                  </h3>
                <div className="m-0">
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <FormGroup>
                        <Input className="form-input inputPlaceHolderColor" name="nofcore" placeholder="No of vCPUs" type="number" min="1" onChange={handleUserInputChange} required/>
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <Input className="form-input inputPlaceHolderColor" name="nofclicks" placeholder="No of clicks by a user per minute" value={clickscount} type="number"  min="10" onChange={handleUserInputChange} required/>
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup className="mt-3">
                          <Dropdown options={usecase_options} className="form-control dropdownControl" onChange={updateUsecase1} value={usecase1} placeholder="Select an option" />
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <Input className="form-input inputPlaceHolderColor" name="avgtime" placeholder="Load time taken by the website to load" min="1" value={avgtime} type="number" onChange={handleUserInputChange} required/>
                      </FormGroup>
                    </div>
                    <br></br>
                    <div className="col-md-12">
                    <FormGroup className="pt-4">
                    <Button className="button button-wide-mobile button-sm"size="md" onClick={handleButton1}>Get My Result</Button>
                      </FormGroup>
                    </div>
                    <br></br>
<p style={{lineHeight:2, textAlign:"left", color:"#ffffff", fontSize:10}}>
Hint to access load time:<br></br>
&nbsp;&nbsp;1.	In a new tab, open the site for which you want to know the load time.<br></br>
&nbsp;&nbsp;2.	The next step varies depending on the browser you use; here are some examples from commonly used browsers.<br></br>
&nbsp;&nbsp;&nbsp;&nbsp; 2.1.	In case if you are using Microsoft Edge browser, press the F12 key then, select "Open DevTools" to move on to point 3.<br></br>
&nbsp;&nbsp;&nbsp;&nbsp; 2.2.	In case if you are using Safari browser, first enable Develop menu by selecting, Safari > Preferences, click Advanced, then select “Show Develop menu in menu bar”. Now go back to the website and right click on the mouse and select Inspect Element, to move on to point 3.<br></br>
&nbsp;&nbsp;&nbsp;&nbsp; 2.3.	In case if you are using Chrome browser, move on to point 3. <br></br>
&nbsp;&nbsp;3.	To see the load time, select the network tab and reload the page.<br></br>
</p>
                  </div>
                </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
